import Image from "next/image";
import css from "./PerksCard.module.scss";

const PerksCard = ({ image, title }) => {
  return (
    <div className={css.card}>
      <Image src={image} height={80} width={80} />
      <br/>
      <span className={css.title} dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  );
};

export default PerksCard;
