import React from "react";
import css from "./GiftsView.module.scss";
import GiftsGrid from "./components/GiftsGrid/GiftsGrid";
import { withTranslation } from "../../i18n";
import ListTitle from "@/shared/textes/ListTitle/ListTitle";
import Link from "next/link";
import LinksProvider from "@/services/http/LinksProvider";

const namespacesRequired = ["home"];

const GiftsView = ({ gifts, title, subtitle, content, buttonLabel }) => {

  return (
    <div className={css.home__gifts}>
      <GiftsGrid customClass={css.home__grid} gifts={gifts} />
      <div className={css.home__grid__overlay} />

      <div className={css.home__gifts__content}>
        <ListTitle
          title={title}
          subtitle={subtitle}
          customClassTitle={css.home__gifts__content__title}
          customClassSubtitle={css.home__gifts__content__subtitle}
        />
        <p>{content}</p>
        <Link
          href={`${LinksProvider.get(
            LinksProvider.ROUTES.PRODUCT.SEARCH_NO_PARAMS
          )}`}
        >
          <a className={css.btn__light}>
            <span>{buttonLabel}</span>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default withTranslation(namespacesRequired)(GiftsView);
