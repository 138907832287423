import Image from "next/image";
import css from "./GiftsGrid.module.scss";
import React, { useEffect, useState } from "react";

const ODD_COLUMNS_SPEED = 0.2;
const EVEN_COLUMNS_SPEED = 0.1;

const splitArray = function (array, size) {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...splitArray(tail, size)];
};

const offset = (el) => {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

const GiftsGrid = ({ customClass, gifts }) => {
  const [gridRef] = useState(React.createRef());

  while (gifts.length < 15) {
    gifts.push(gifts[Math.floor(Math.random() * (gifts.length - 1))]);
  }

  const rows = splitArray(gifts, 3);

  const handleScroll = () => {
    if (!gridRef || !gridRef.current || window.scrollY == undefined) {
      return;
    }

    const scroll =
      window.scrollY -
      offset(gridRef.current).top +
      gridRef.current.offsetHeight;
    if (scroll < 0) {
      return;
    }

    const domRows = gridRef.current.children[0].children;
    for (let i = 1; i <= domRows.length; i++) {
      const odd = i % 2 == 1;
      const transform = `translateY(${
        -scroll * (odd ? ODD_COLUMNS_SPEED : EVEN_COLUMNS_SPEED)
      }px)`;
      domRows[i - 1].style.transform = transform;
      domRows[i - 1].style.MozTransform = transform;
      domRows[i - 1].style.webkitTransform = transform;
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 875) {
      window.addEventListener("scroll", handleScroll);
      handleScroll();
    }
  }, []);

  return (
    <div className={`${css.gifts} ${customClass || ""}`} ref={gridRef}>
      <div className={css.gifts__grid}>
        {rows.map((row, key) => {
          return (
            <div key={key} className={css.gifts__row}>
              {row.map((gift, index) => {
                return (
                  <>
                    <div key={index} className={css.gift}>
                    <div className={css.gift}>
                      <Image
                        src={
                          gift.cover_photo?.storage_service_url
                            ? gift.cover_photo?.storage_service_url
                            : "/static/images/categories/default-img-1.jpg"
                        }
                        layout="responsive"
                        quality={80}
                        width={304}
                        height={364}
                      />
                    </div>
                    <div className={css.gift__img__content}>
                      <h2>{gift.brand}</h2>
                      <p>{gift.name}</p>
                    </div></div>
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GiftsGrid;
