import css from "./PerksCarousel.module.scss";
import PerksCard from "../PerksCard";
import { useState, useRef } from "react";
import Button from "@/shared/button/simpleButton/Button";
import Swiper from "react-id-swiper";
import { isMobile } from "react-device-detect";
import Image from 'next/image';

const PerksCarousel = ({ title, subtitle, perks }) => {

  const ref = useRef(null);
  const [swiper, setSwiper] = useState(0);

  const handleSwipedLeft = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handleSwipedRight = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.wrapper__text}>
        <div>
          <h2 className={css.heading}>{title}</h2>
          <span className={css.subtitle}>{subtitle}</span>
        </div>
        <div className={css.wrapper__text__controls}>
          <div className={css.btn_container}>
            <Button className={css.btn_light} onClick={handleSwipedRight}>
              <Image
                src="/static/images/pictos/icon-back-arrow.svg"
                alt="Back Arrow"
                width={18}
                height={18}
              />
            </Button>
          </div>
          <div className={css.btn_container} onClick={handleSwipedLeft}>
            <Button className={css.btn_light}>
              <Image
                src="/static/images/pictos/icon-back-arrow.svg"
                alt="Back Arrow"
                style={{ transform: "rotate(180deg)" }}
                width={18}
                height={18}
              />
            </Button>
          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <Swiper
          ref={ref}
          spaceBetween={16}
          width={isMobile ? 240 : 280}
          getSwiper={setSwiper}
          wrapperClass={css.SwiperWrapper}
        >
          {perks?.map((perk, index) => (
            <div>
              <PerksCard
                key={`perk__${index}`}
                image={perk.image}
                title={perk.title}
              />
            </div>
          ))}
        </Swiper>
        <div className={css.SwiperWrapper__Overlay} />
      </div>
    </div>
  );
};

export default PerksCarousel;
